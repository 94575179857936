.file-upload-container .upload-box {
    border: 2px dashed;
}

.border-bottom-offset {
    position: relative;
}

.border-bottom-offset::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 4px solid #007ea5;
    /* Change color and thickness as needed */
    transform: translateY(10px);
    /* Adjust the offset here */
}

.rxAdminAlert button span {
    font-size: 1.5rem;
}

.rxAdminAlert button {
    padding: 0.75rem 1.25rem !important;
}

.rxAdminAlert .alert-success {
    padding-right: 0;
}

.rxAdmin-progress-bar .progress {
    height: 0.5rem;
}