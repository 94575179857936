@import './src/styles/variables';

.info-box-container {
    display: inline;
    margin-left: 10px;
}

.info-content {
    color: #ffffff;
    font-size: 0.8rem !important;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
}

.info-box {
    position: absolute;
    width:fit-content;
    background-color: $primary;
    padding: 5px 10px;
    margin: 40px;
    float: left;
    z-index: 1;
  }

  .info-box ul {
    padding: 10px;
    margin-bottom: 0px;
  }

  .info-box ul li {
    padding-bottom: 5px;
  }

  .info-box ul li span {
    display: block;
    margin-left: 0.2em;
    color: #fff;
  }



.arrow-top, .arrow-top-left, .arrow-top-right  {
    margin-top: 40px;
  }

.arrow-top:after {
    content: " ";
    position: absolute;
    right: 30px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $primary;
  }

  .arrow-top-left:after {
    content: " ";
    position: absolute;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $primary;

  }

  .arrow-top-right:after {
    content: " ";
    position: absolute;
    top: 15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $primary;

  }



.info-box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid $primary;
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .info-box.arrow-right:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid $primary;
    border-bottom: 15px solid transparent;
  }
