@import "./src/styles/variables";

.cmx__case-detail-main-section-journey {
  &__three-dots {
    color: $primary;
  }

  &__check {
    color: $success;
  }

  &__title {
    color: $primary;
  }

  &__sub-status {
    font-size: 0.70rem;
  }

  &__block {
    width: 100%;
    border: 0.2rem solid;
    border-left: none;
    border-right: none;
    padding: 0.25rem;
    padding-left: 2rem;
    padding-right: 0;
    height: 5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: -2rem;
      width: 0;
      height: 0;
      border-left: 2rem solid;
      border-color: var(--block-border-var);
      border-top: 2.6rem solid transparent;
      border-bottom: 2.6rem solid transparent;
      z-index: 10;
    }

    &:before {
      content: '';
      position: absolute;
      right: -1.8rem;
      width: 0;
      height: 0;
      border-left: 1.8rem solid;
      border-color: var(--block-background-var);
      border-top: 2.4rem solid transparent;
      border-bottom: 2.4rem solid transparent;
      z-index: 20;
    }

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
      margin-left: 1rem;
      padding-left: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-left: 2px solid;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
      border-right: 2px solid;
    }

    &:last-child:before {
      border: none;
    }

    &:last-child:after {
      border: none;
    }

    &__icon {
      width: 35px;
    }
  }
}
