@import './src/styles/variables';

.cmx__case-detail-main-section-summary-tab {
  &__card {
    border: none !important;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  &__card-header {
    border: none !important;
    cursor: pointer;
    display: flex;
    align-items: center;

    hr {
      margin-left: 1rem;
      margin-right: 1rem;
      border: 1px solid $primary;
      flex-grow: 1;
    }
  }

  &__card-body {
    padding-left: 2rem !important;
    font-size: 0.8rem !important;
  }
}

.headerColor {
  color: #444444 !important;
}

.details-table {
  font-size: 0.8rem !important;
}
