@import './src/styles/variables';

.cmx__case-detail-view-container {
  background-color: var(--cmx-grey-5);
  display: flex;
  min-height: 90vh;
}

.cmx__case-detail-sidebar-patient-info__a-request-consent {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.consent-modal-dialog {
  width: fit-content !important;
  .modal-content {
    border-radius: 0;
    border: none;
    outline: none;
    .modal-body {
      padding: 0;
    }
  }
  @media only screen and (min-width: 576px) {
    max-width: 625px !important;
  }
}
