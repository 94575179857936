@import './src/styles/variables';
.cmx__consent_status__span-consent-on-file {
  padding: 7px 10px;
  border: 2px solid $danger;
  color: $danger;
  font-size: 0.7rem;
  text-transform: uppercase;
  &.success {
    border-color: $cmx-green !important;
    color: $cmx-green !important;
  }
}
