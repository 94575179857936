$primary: var(--primary);
$primary: var(--primary);
$secondary: var(--secondary);
$success: var(--success);
$info: var(--info);
$warning: var(--warning);
$danger: var(--danger);
$light: var(--light);
$dark: var(--dark);
$cmx-green: var(--cmx-green);
$transparent: var(--transparent);
$gray: var(--gray);
$cmx-slate-20: var(--cmx-slate-20);
$cmx-grey-5: var(--cmx-grey-5);
$cmx-blue-60: var(--cmx-blue-60);
$cmx-grey-20: var(--cmx-grey-20);
$login-page-color: var(--login-page-color);
$login-text-custom: var(--login-text-custom);
$five9-chat-icon1: var(--five9-chat-icon1);
$five9-chat-icon2: var(--five9-chat-icon2);
$five9-ui-header: var(--five9-ui-header);
$five9-action-btn-background: var(--five9-action-btn-background);
$five9-action-btn-border: var(--five9-action-btn-border);
$primary-btn-color: var(--primary-btn-color);
$selected-tab-color: var(--selected-tab-color);
$horizontal-line-color: #66b2c9;

$gray-100: #f5f5f5;
$gray-200: #dadada;
$gray-300: #b4b4b4;
$gray-400: #8f8f8f;
$gray-500: #696969;
$gray-600: #575757;
$gray-700: #444444;
$gray-800: #363636;
$gray-900: #292929;

$body-color: $gray-700;
$link-color: $cmx-green;
$white: #fff;
