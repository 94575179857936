@import "../styles/variables";

.contactUs-requirement-box {
    display: inline;
    margin-left: 10px;
}

.contactUs-requirement {
    color: #ffffff;
    font-size: 0.8rem !important;
    text-align: left;
    font-weight: bold;
    text-transform: none;
}
.contactUs-Box {
    position: absolute;
    width:300px;
    background-color: whitesmoke;
    padding: 20px;
    margin: 40px;
    float: left;
    z-index: 1;
    color: darkgrey;
  }
  .arrow-top-left:after {
    content: " ";
    position: absolute;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $primary;

  }
  .arrow-top, .arrow-top-left {
    margin-top: 40px;
  }

.arrow-top:after {
    content: " ";
    position: absolute;
    right: 30px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $primary;
  }

  .contactUs-Box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid whitesmoke;
    border-left: none;
    border-bottom: 15px solid transparent;
  }
