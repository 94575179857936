@import './src/styles/variables';

.cmx__patient-detail-sidebar-nav {
  border: none !important;
  margin-bottom: 1rem !important;

  &__nav-item {
    margin: 0.125rem 0;
  }

  &__nav-text {
    margin-left: 1rem;
  }

  &__nav-link-unselected {
    border-color: $primary !important;
    background-color: $primary !important;
    color: $cmx-slate-20 !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
  }

  &__nav-link-unselected:hover {
    border-color: $cmx-grey-5 !important;
    background-color: $cmx-grey-5 !important;
    color: $primary !important;
  }

  &__nav-link-selected {
    border-color: $cmx-grey-5 !important;
    background-color: $cmx-grey-5 !important;
    color: $primary !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
  }
}

.cmx__rx-admin-icon {
  border-radius: 0px !important;
}