@import './src/styles/variables';

.cmx__file_upload {
  .consent-text-font {
    font-size: 0.75rem !important;
  }
  .cmx__file_upload__p-drag-drop {
    text-align: center;
  }

  .cmx__file_upload__button-upload-file {
    box-shadow: none;
    border-color: $cmx-green !important;
    color: $cmx-green !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    &:enabled:hover,
    &:not(:disabled):not(.disabled):active {
      background: $cmx-green !important;
      border-color: $cmx-green !important;
    }
    &:active {
      box-shadow: none;
    }
  }

  .drop-upload {
    &__space {
      border: 1px dashed #547f0b;
      border-radius: 5px;
      padding: 13px 7px;
      text-align: center;
    }

    &__wrapper {
      display: flex;
      align-items: flex-end;
      gap: 15px;
    }

    &__help-text {
      font-size: 14px;
      color: #8f8f8f;
      line-height: 12px;
      margin-top: 10px;
    }
  }
  .margin-40-left {
    margin-left: 40px;
  }
}
