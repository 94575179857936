@import './src/styles/variables';

.cmx__case-detail-main-section-tabs {
  a {
    &.nav-item {
      &.nav-link {
        color: $primary !important;

        &:hover {
          color: #004459 !important;
        }
        &.active {
          color: $secondary !important;
        }
      }
    }
  }

  &__tab-content {
    border-radius: 0 0 0.25rem 0.25rem !important;
    border-top: none !important;
  }
}

.cmtx__message-tab {
  position: relative;
  &__icon {
    position: absolute;
    font-size: 14px;
    margin-left: 5px;
    top: -7px;
    right: -15px;
  }
}

.message-notification {
  padding: 3px;
  font-size: 10px;
  position: absolute;
  top: -8px;
  right: -15px;
  background: #2c83a9;
  color: white;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.details-table {
  width: 100%;
  .title-row {
    td {
      padding-top: 60px;
      p {
        text-transform: uppercase;
      }
    }
    &:first-child {
      td {
        padding-top: 0;
      }
    }
  }
  td {
    vertical-align: baseline;
    padding: 10px 5px;
    p {
      margin: 0;
    }
  }
}

.caps
 {
  text-transform:uppercase
}
