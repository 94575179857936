@import './src/styles/variables';

.cmx__request-consent-modal {
  max-width: 647px;

  .cmx__request-consent-modal__h4-title {
    padding: 15px;
    margin: 0;
    color: #2596be;
    font-size: 20px;
    font-weight: bold;
    text-transform: none;
  }

  .cmx__request-consent-modal__hr-separator {
    margin: 0;
  }

  .cmx__request-consent-modal__div-content {
    padding: 30px;
    .cmx__request_consent-modal__p-error-message {
      font-size: 14px;
      color: $danger;
    }
    .cmx__request_consent_modal_upload_consent {
      .cmx__request_consent_modal__badge-file-name {
        background: #2596be;
      }
    }
    .cmx__request_consent_modal-checkbox-upload-declaration {
      input {
        cursor: pointer;
        width: 18px;
        height: 18px;

        &:checked {
          accent-color: #2596be;
        }
      }
    }
    .cmx__request_consent-modal__span-consent-on-file {
      padding: 5px 10px;
      border: 2px solid $danger;
      color: $danger;
      font-size: 0.7rem;
      text-transform: uppercase;
    }
    .cmx_consent_status__button-invite {
      align-self: flex-start;
      box-shadow: none;
      border-color: $cmx-green !important;
      color: $cmx-green !important;
      font-weight: 500 !important;
      text-transform: uppercase;
      &:disabled {
        cursor: default;
        pointer-events: none;
        color: $gray !important;
        border-color: $gray !important;
      }
      &:enabled:hover,
      &:not(:disabled):not(.disabled):active {
        background: $cmx-green !important;
        border-color: $cmx-green !important;
        color: $light !important;
      }
    }
    .cmx__request_consent-modal__label-select-consent-type {
      text-transform: uppercase;
      color: $gray;
      font-size: 15px;
    }
    .cmx__request_consent-modal__div-request-options {
      margin-bottom: 15px;
      .cmx__request_consent-modal__Check-option {
        margin-right: 10px;

        input,
        .form-check-label {
          cursor: pointer;
          color: $dark;
        }

        &.disabled {
          input,
          .form-check-label {
            pointer-events: none;
            cursor: default;
          }
        }

        input {
          accent-color: #2596be;
          width: 20px;
          height: 20px;
        }

        .form-check-label {
          margin-left: 5px;
          padding-top: 3px; // TODO fix with classname
        }
      }
    }

    .cmx__request_consent-modal__div-verification-info {
      padding-left: 10px;
      .cmx__request_consent-modal__label-verification-code {
        margin: 0;
        color: $dark;
        font-weight: bold;
        opacity: 0.7;
      }
      .cmx__request_consent-modal__span-verification-code {
        color: #662c8f;
        font-size: 25px;
        font-weight: bold;
      }
      .cmx__request_consent-modal__div-verification-url-wrap {
        flex-basis: 60%;
        .cmx__request_consent-modal__div-verification-url {
          flex-basis: 85%;
          .cmx__request_consent-modal__a-verification-url {
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }

    .cmx__request_consent-modal__div-verification-info,
    .cmx__request_consent-modal__div-verification-url-wrap {
      .cmx__request_consent_modal__copy-icon {
        cursor: pointer;
        &:hover {
          scale: 1.1;
        }
      }
      .cmx__request_consent_modal__copied-icon {
        rotate: 10deg;
        scale: 1.2;
      }
    }
  }

  .cmx__request_consent-modal__button-close {
    padding: 0 !important;
    margin: 0 30px 0px 0;
    border: none;
    background: transparent;
    color: $danger;
    font-weight: 500 !important;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 767px) {
    min-width: 625px;
  }
}
